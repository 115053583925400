module.exports = [{
      plugin: require('/codebuild/output/src291563270/src/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src291563270/src/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"tomato","showSpinner":false},
    },{
      plugin: require('/codebuild/output/src291563270/src/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.datumhq.com"},
    },{
      plugin: require('/codebuild/output/src291563270/src/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-139117563-1","head":true},
    },{
      plugin: require('/codebuild/output/src291563270/src/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
