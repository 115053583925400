// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/codebuild/output/src291563270/src/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/codebuild/output/src291563270/src/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/codebuild/output/src291563270/src/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cloud-js": () => import("/codebuild/output/src291563270/src/src/pages/cloud.js" /* webpackChunkName: "component---src-pages-cloud-js" */),
  "component---src-pages-contact-js": () => import("/codebuild/output/src291563270/src/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-digital-transformation-js": () => import("/codebuild/output/src291563270/src/src/pages/digital-transformation.js" /* webpackChunkName: "component---src-pages-digital-transformation-js" */),
  "component---src-pages-enterprise-support-js": () => import("/codebuild/output/src291563270/src/src/pages/enterprise-support.js" /* webpackChunkName: "component---src-pages-enterprise-support-js" */),
  "component---src-pages-index-js": () => import("/codebuild/output/src291563270/src/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("/codebuild/output/src291563270/src/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-software-development-js": () => import("/codebuild/output/src291563270/src/src/pages/software-development.js" /* webpackChunkName: "component---src-pages-software-development-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/codebuild/output/src291563270/src/.cache/data.json")

